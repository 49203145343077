import { LANG } from '@/constants'

export default () => {
    const i18n = useI18n();
    return {
        setLang : async (language) => {
            const lang = LANG[language] || LANG.ar
            localStorage.setItem('adtv-guest-language',lang);
            return await i18n.setLocale(lang)
        },
        //replace mode
        setLangWidthReplace: async(language,path) => {
            const lang = LANG[language] || LANG.ar
            i18n.locale.value = lang
            localStorage.setItem('adtv-guest-language',lang);
            await i18n.setLocaleCookie(lang)
            path && navigateTo({
                path,
                replace:true
            })
        }
    }
}