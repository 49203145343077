<template>
  <button
    type="button"
    :class="[verticalAlignment]"
    class="flex justify-start gap-x-2"
    @click.prevent.stop="onChange"
  >
    <div class="w-6 h-6 rounded-[4px]" @click.prevent.stop="onChange">
      <Icon v-if="inputValue" name="IconCheckboxOn" size="24"></Icon>
      <Icon v-else name="IconCheckboxOff" size="24"></Icon>
    </div>
    <slot></slot>
  </button>
</template>

<script setup>
import { useField } from "vee-validate";

const emit = defineEmits(["change"]);
const props = defineProps({
  name: String,
  verticalAlignment: {
    type: String,
    default: () => "items-center",
  },
});

const name = toRef(props, "name");

const { value: inputValue, handleChange } = useField(name, undefined);

const onChange = (e) => {
  e?.target?.blur();
  emit("change", !inputValue.value);
  handleChange(!inputValue.value);
};
</script>

<style lang="scss" scoped></style>
